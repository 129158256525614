import { createContext, useState } from 'react';
import { NotificationTypes } from '../constants';

export const NotificationContext = createContext(null);

export const NotificationContextProvider = ({ children }) => {
  const [notification, setNotification] = useState('');

  const showSuccessNotification = () => {
    setNotification(NotificationTypes.success);
    setTimeout(() => {
      setNotification('');
    }, 5000);
  };

  const showWarningNotification = () => {
    setNotification(NotificationTypes.warning);
    setTimeout(() => {
      setNotification('');
    }, 5000);
  };

  return (
    <NotificationContext.Provider
      value={{
        notification,
        showSuccessNotification,
        showWarningNotification,
      }}>
      {children}
    </NotificationContext.Provider>
  );
};

import { useContext } from 'react';
import { LoadingContext } from '../context/loadingContext';

export const useLoading = () => {
  const loadingContext = useContext(LoadingContext);

  if (!loadingContext) throw new Error('useLoading must be used within a LoadingContextProvider');

  return loadingContext;
};

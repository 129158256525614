import logo from '../../assets/images/Logo.svg';
import './style.scss';
import Button, { ButtonTypes } from '../ui/Button';
import addSimpleIcon from '../../assets/images/add-simple.svg';
import { useModal } from '../../hooks/useModal';
import heart from '../../assets/images/heart.svg';
import ua from '../../assets/images/ua.svg';
import { motion } from 'framer-motion';

const overlayVariants = {
  hidden: { opacity: 0, transition: { duration: 0.1 } },
  visible: { opacity: 1, transition: { duration: 0.1 } },
};

const contentVariants = {
  hidden: { y: 300, transition: { duration: 0.3, ease: 'easeOut' } },
  visible: { y: 0, transition: { duration: 0.3, ease: 'easeOut' } },
};

export const MobileMenu = () => {
  const { isMenuModal, toggleJoinModal, toggleCooperateModal, toggleMenuModal } = useModal();
  const overlayStyle = { height: isMenuModal ? '100vh' : 'auto' };
  return (
    <>
      <motion.div
        style={overlayStyle}
        className="mobile-menu-overlay"
        initial={false}
        animate={isMenuModal ? 'visible' : 'hidden'}
        variants={overlayVariants}></motion.div>
      <motion.div
        className="mobile-menu"
        initial={false}
        animate={isMenuModal ? 'visible' : 'hidden'}
        variants={contentVariants}>
        <div className="mobile-menu-button" onClick={toggleMenuModal}>
          {isMenuModal ? (
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M1.80762 1.80469L20.1924 20.1895" stroke="#7E9D00" strokeWidth="3" />
              <path d="M1.80762 20.1953L20.1924 1.81054" stroke="#7E9D00" strokeWidth="3" />
            </svg>
          ) : (
            <svg
              width="26"
              height="24"
              viewBox="0 0 26 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M0 2H26" stroke="#7E9D00" strokeWidth="3" />
              <path d="M0 12H26" stroke="#7E9D00" strokeWidth="3" />
              <path d="M0 22H26" stroke="#7E9D00" strokeWidth="3" />
            </svg>
          )}
        </div>
        <div className="mobile-menu-wrapper">
          <img src={logo} alt="logo" />
          <div className="mobile-menu-made-with-love-wrapper">
            <div className="mobile-menu-made-with-love">
              <span>Made with</span>
              <img src={heart} alt="heart" />
              <span>in</span>
              <img src={ua} alt="ua" />
              <span>Ukraine</span>
            </div>
          </div>
          <Button icon={addSimpleIcon} onClick={toggleJoinModal}>
            Join the waitlist
          </Button>
          <Button type={ButtonTypes.secondary} onClick={toggleCooperateModal}>
            Interested in partnering?
          </Button>
        </div>
      </motion.div>
    </>
  );
};

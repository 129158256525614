import { createContext, useState } from 'react';

export const LoadingContext = createContext(null);

export const LoadingContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);

  const changeLoading = (value) => {
    setLoading(value);
  };

  return (
    <LoadingContext.Provider
      value={{
        loading,
        changeLoading,
      }}>
      {children}
    </LoadingContext.Provider>
  );
};

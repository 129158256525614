import { createContext, useState } from 'react';

export const ActiveScreenContext = createContext(null);

export const ActiveScreenContextProvider = ({ children }) => {
  const [activeScreen, setActiveScreen] = useState(1);

  const changeScreen = (value) => {
    setActiveScreen(value);
  };

  return (
    <ActiveScreenContext.Provider
      value={{
        activeScreen,
        changeScreen,
      }}>
      {children}
    </ActiveScreenContext.Provider>
  );
};

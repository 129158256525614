import { useEffect, useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import Marquee from 'react-fast-marquee';
import Heading from '../ui/Heading';
import Label from '../ui/Label';
import { Pounder } from '../pounder/Pounder';
import { useScreenSize } from '../../hooks/useScreenSize';
import starsIcon from '../../assets/images/stars.svg';
import boltFilledIcon from '../../assets/images/bolt-filled.svg';
import usersGroupRoundedIcon from '../../assets/images/users-group-rounded.svg';
import usersGroupFilledIcon from '../../assets/images/users-group-filled-rounded.svg';
import userBlockRoundedIcon from '../../assets/images/user-block-rounded.svg';
import capricornIcon from '../../assets/images/capricorn.svg';
import ghostIcon from '../../assets/images/ghost.svg';
import heartsIcon from '../../assets/images/hearts.svg';
import heartBrokenIcon from '../../assets/images/heart-broken.svg';
import moonSleepIcon from '../../assets/images/moon-sleep.svg';
import userPlusIcon from '../../assets/images/user-plus.svg';
import cityIcon from '../../assets/images/city.svg';
import automationsVideo from '../../assets/videos/AI/Automations.mp4';
import dashboardVideo from '../../assets/videos/AI/Dashboard.mp4';
import './style.scss';
import { useActiveScreen } from '../../hooks/useActiveScreen';

export const SectionThree = () => {
  const automationsRef = useRef(null);
  const dashboardRef = useRef(null);
  const rowOneRef = useRef(null);
  const isAutomationsInView = useInView(automationsRef);
  const isDashboardInView = useInView(dashboardRef);
  const isRowOneInView = useInView(rowOneRef);
  const { changeScreen } = useActiveScreen();
  const { width } = useScreenSize();

  const sectionThreeTitleAnimation = {
    hidden: {
      y: 20,
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.7, ease: 'easeOut' },
    },
  };

  const cardAnimation = {
    hidden: {
      y: 20,
      opacity: 0,
    },
    visible: (custom) => ({
      y: 0,
      opacity: 1,
      transition: { duration: 0.7, ease: 'easeOut', delay: custom * 0.35 },
    }),
  };

  const ref1 = useRef(null);
  const ref2 = useRef(null);

  const refs = [ref1, ref2];

  const playVideos = () => {
    refs.forEach((ref) => {
      if (ref.current) {
        ref.current.play();
      }
    });
  };

  useEffect(() => {
    if (ref1.current && isAutomationsInView) {
      ref1.current.play();
    }
  }, [isAutomationsInView]);

  useEffect(() => {
    if (ref2.current && isDashboardInView) {
      ref2.current.play();
    }
  }, [isDashboardInView]);

  useEffect(() => {
    if (isRowOneInView) {
      changeScreen(4);
    }
  }, [isRowOneInView]);

  useEffect(() => {
    document.addEventListener('touchstart', playVideos);

    return () => {
      document.removeEventListener('touchstart', playVideos);
    };
  }, []);

  return (
    <div className="section-three">
      <div className="section-three-row">
        <motion.div
          initial="hidden"
          whileInView="visible"
          exit="hidden"
          variants={sectionThreeTitleAnimation}
          viewport={{ amount: 0.3, once: true }}
          className="section-three-title-wrapper">
          <Label icon={starsIcon}>operate smarter</Label>
          <Heading>Streamline your marketing routine with easy to use AI features.</Heading>
        </motion.div>
        <div className="section-three-cards-wrapper">
          <div className="section-three-cards-row-1" ref={rowOneRef}>
            <motion.div
              initial="hidden"
              whileInView="visible"
              exit="hidden"
              custom={0}
              variants={cardAnimation}
              viewport={{ amount: 0.2, once: true }}
              className="section-three-card card-1">
              <div className="section-three-card-header">
                <span className="card-title">Automated Segmentation</span>
                <span className="card-description">
                  Easily categorize your contacts within seconds using the advanced capabilities of
                  artificial intelligence.
                </span>
              </div>
              <div className="section-three-card-1-items-wrapper">
                <Marquee autoFill={true} speed={15}>
                  <div className="section-three-card-1-items-row">
                    <CardItem icon={heartsIcon} text={'Engaged'} amount={'56,981'} />
                    <CardItem icon={userBlockRoundedIcon} text={'Zombies'} amount={'94'} />
                    <CardItem icon={boltFilledIcon} text={'Active'} amount={'142,032'} />
                    <CardItem icon={capricornIcon} text={'Capricorns'} amount={'142,032'} />
                  </div>
                </Marquee>
                <Marquee autoFill={true} speed={25}>
                  <div className="section-three-card-1-items-row">
                    <CardItem icon={ghostIcon} text={'Ghosts'} amount={'1,481'} />
                    <CardItem icon={usersGroupFilledIcon} text={'New Segment 2'} amount={'1'} />
                    <CardItem icon={heartBrokenIcon} text={'Unengaged'} amount={'5,826'} />
                  </div>
                </Marquee>
                <Marquee autoFill={true} speed={20}>
                  <div className="section-three-card-1-items-row">
                    <CardItem icon={moonSleepIcon} text={'Dormant'} amount={'12'} />
                    <CardItem icon={userPlusIcon} text={'Just Joined'} amount={'15,532'} />
                    <CardItem icon={cityIcon} text={'NY Audience'} amount={'2,956'} />
                  </div>
                </Marquee>
              </div>
            </motion.div>
            <motion.div
              initial="hidden"
              whileInView="visible"
              exit="hidden"
              custom={1}
              variants={cardAnimation}
              viewport={{ amount: 0.2, once: true }}
              className="section-three-card card-2">
              <div className="section-three-card-header">
                <span className="card-title">Dynamic Content</span>
                <span className="card-description">
                  Personalize thousands of emails rapidly with dynamic blocks, content, languages,
                  and more.
                </span>
              </div>
              <Pounder />
            </motion.div>
          </div>
          <div className="section-three-cards-row-2">
            {width < 576 && (
              <motion.div
                ref={dashboardRef}
                initial="hidden"
                whileInView="visible"
                exit="hidden"
                custom={3}
                variants={cardAnimation}
                viewport={{ amount: 0.1, once: true }}
                className="section-three-card card-4">
                <div className="section-three-card-header">
                  <span className="card-title">Predictive Analytics</span>
                  <span className="card-description">
                    Harness the power of data: collect, analyze, and forecast with intelligent
                    analytics.
                  </span>
                </div>
                <div className="section-three-card-4-image-wrapper">
                  <video
                    ref={ref2}
                    width={'100%'}
                    height={'auto'}
                    playsInline={true}
                    autoPlay={false}
                    loop={false}
                    muted={true}>
                    <source src={dashboardVideo} type="video/mp4" />
                  </video>
                </div>
              </motion.div>
            )}
            <motion.div
              ref={automationsRef}
              initial="hidden"
              whileInView="visible"
              exit="hidden"
              custom={2}
              variants={cardAnimation}
              viewport={{ amount: 0.3, once: true }}
              className="section-three-card card-3">
              <div className="section-three-card-header">
                <span className="card-title">Smart Automations</span>
                <span className="card-description">
                  Automate triggers utilizing data collected from users to optimize the workflow.
                </span>
              </div>
              <div className="section-three-card-3-image-wrapper">
                <video
                  ref={ref1}
                  width={'100%'}
                  height={'100%'}
                  playsInline={true}
                  autoPlay={false}
                  loop={false}
                  muted={true}>
                  <source src={automationsVideo} type="video/mp4" />
                </video>
              </div>
            </motion.div>
            {width > 576 && (
              <motion.div
                ref={dashboardRef}
                initial="hidden"
                whileInView="visible"
                exit="hidden"
                custom={3}
                variants={cardAnimation}
                viewport={{ amount: 0.3, once: true }}
                className="section-three-card card-4">
                <div className="section-three-card-header">
                  <span className="card-title">Predictive Analytics</span>
                  <span className="card-description">
                    Harness the power of data: collect, analyze, and forecast with intelligent
                    analytics.
                  </span>
                </div>
                <div className="section-three-card-4-image-wrapper">
                  <video
                    ref={ref2}
                    width={'100%'}
                    height={'auto'}
                    playsInline={true}
                    autoPlay={false}
                    loop={false}
                    muted={true}>
                    <source src={dashboardVideo} type="video/mp4" />
                  </video>
                </div>
              </motion.div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const CardItem = ({ icon, text, amount }) => {
  return (
    <div className="section-three-card-1-item">
      <div className="section-three-card-1-item-left">
        <img src={icon} alt="" />
        <span>{text}</span>
      </div>
      <div className="section-three-card-1-item-right">
        <img src={usersGroupRoundedIcon} alt="" />
        <span>{amount}</span>
      </div>
    </div>
  );
};

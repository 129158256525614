import axios from 'axios';

export async function subscribeInterests(email, interested) {
  try {
    return await axios.post(`/api/subscription/interest`, {
      email: email,
      interested: interested,
    });
  } catch {}
}

export async function subscribeWitelist(email) {
  try {
    return await axios.post(`/api/subscription/witelist`, {
      email: email,
    });
  } catch {}
}

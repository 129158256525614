import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectCreative } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import './style.scss';
import { useState } from 'react';

export const CarouselTypes = {
  video: 'video',
  image: 'image',
};

export const Carousel = ({ data, type = CarouselTypes.image }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const onSlideChange = (swiper) => {
    setActiveIndex(swiper.realIndex);
  };

  return (
    <div className="carousel">
      <Swiper
        loop={true}
        slidesPerView={1}
        autoplay={{
          delay: 24000,
        }}
        effect={'creative'}
        creativeEffect={{
          prev: {
            translate: ['-121%', 0, -500],
          },
          next: {
            translate: ['121%', 0, -500],
          },
        }}
        modules={[Autoplay, EffectCreative]}
        onSlideChange={onSlideChange}>
        {data.map(({ ref, src, title, description }, i) => (
          <SwiperSlide key={i}>
            {type === CarouselTypes.image && <img src={src} alt="" />}
            {type === CarouselTypes.video && (
              <video ref={ref} playsInline={true} autoPlay={true} loop={false} muted={true}>
                <source src={src} type="video/mp4" />
              </video>
            )}
            <div className="slider-pagination-item">
              <span className="slider-pagination-title">{title}</span>
              <span className="slider-pagination-description">{description}</span>
            </div>
          </SwiperSlide>
        ))}
        <div slot="container-end" className="slider-dots-wrapper">
          {data.map((_, index) => {
            return (
              <div
                key={index}
                className={`slider-dot${activeIndex === index ? ' active' : ''}`}></div>
            );
          })}
        </div>
      </Swiper>
    </div>
  );
};

import React from 'react';
import { Loader } from './components/ui/Loader';
import { AnimatePresence } from 'framer-motion';
import { MobileMenu } from './components/mobileMenu/MobileMenu';
import { useLoading } from './hooks/useLoading';
import { Header } from './components/header/Header';
import { Footer } from './components/footer/Footer';
import { RunningString } from './components/runningString/RunningString';
import { Hero } from './components/hero/Hero';
// import { SectionOne } from './components/sectionOne/SectionOne';
import { SectionTwo } from './components/sectionTwo/SectionTwo';
import { SectionThree } from './components/sectionThree/SectionThree';
import { SectionFive } from './components/sectionFive/SectionFive';
import { SectionFour } from './components/sectionFour/SectionFour';
import { Calculator } from './components/calculator/Calculator';
import Modal, { ModalTypes } from './components/ui/Modal';
import { useModal } from './hooks/useModal';
import { useScreenSize } from './hooks/useScreenSize';
import './App.scss';
import { Notification } from './components/ui/Notification';
import { useActiveScreen } from './hooks/useActiveScreen';

function App() {
  const { width } = useScreenSize();
  const { isJoinModal, isCooperateModal, toggleJoinModal, toggleCooperateModal } = useModal();
  const { loading } = useLoading();
  const { activeScreen } = useActiveScreen();

  return (
    <>
      {loading && <Loader />}
      <div className={`app active-screen-${activeScreen}`} id="app">
        {width > 576 && <Header />}
        <Hero />
        <RunningString />
        {/*<SectionOne />*/}
        <SectionTwo />
        <SectionThree />
        <SectionFour />
        <SectionFive />
        <RunningString />
        <Calculator />
        <Footer />
        <AnimatePresence>
          {isJoinModal && <Modal onClick={toggleJoinModal} />}
          {isCooperateModal && <Modal type={ModalTypes.cooperate} onClick={toggleCooperateModal} />}
        </AnimatePresence>
        {width < 576 && <MobileMenu />}
        <Notification />
      </div>
    </>
  );
}

export default App;

import { useContext } from 'react';
import { ActiveScreenContext } from '../context/activeScreenContext';

export const useActiveScreen = () => {
  const activeScreenContext = useContext(ActiveScreenContext);

  if (!activeScreenContext)
    throw new Error('useActiveScreen must be used within a ActiveScreenContextProvider');

  return activeScreenContext;
};

import React from 'react';
import ReactDOM from 'react-dom/client';
import { ModalContextProvider } from './context/modalContext';
import { LoadingContextProvider } from './context/loadingContext';
import { NotificationContextProvider } from './context/notificationContext';
import { ActiveScreenContextProvider } from './context/activeScreenContext';
import App from './App';
import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <LoadingContextProvider>
    <NotificationContextProvider>
      <ModalContextProvider>
        <ActiveScreenContextProvider>
          <App />
        </ActiveScreenContextProvider>
      </ModalContextProvider>
    </NotificationContextProvider>
  </LoadingContextProvider>,
);

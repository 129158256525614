import widgetAddIcon from '../../assets/images/widget-add.svg';
import slide1Video from '../../assets/videos/Slider/slide-1.mp4';
import slide2Video from '../../assets/videos/Slider/slide-2.mp4';
import slide3Video from '../../assets/videos/Slider/slide-3.mp4';
import Label from '../ui/Label';
import Heading from '../ui/Heading';
import 'swiper/css';
import 'swiper/css/mousewheel';
import './style.scss';
import { useEffect, useRef, useState } from 'react';
import {
  AnimatePresence,
  motion,
  useMotionValueEvent,
  useScroll,
  useTransform,
} from 'framer-motion';
import { Carousel, CarouselTypes } from '../carousel/Carousel';
import { useActiveScreen } from '../../hooks/useActiveScreen';

const initialSlide = 0;

export const SectionTwo = () => {
  const [activeSlide, setActiveSlide] = useState(initialSlide);
  const { changeScreen } = useActiveScreen();

  const firstScreen = [0, 1 / 3];
  const secondScreen = [1 / 3, 2 / 3];
  const thirdScreen = [2 / 3, 1];
  const progress = ['0%', '100%'];

  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
  });

  const firstScreenProgress = useTransform(scrollYProgress, firstScreen, progress);
  const secondScreenProgress = useTransform(scrollYProgress, secondScreen, progress);
  const thirdScreenProgress = useTransform(scrollYProgress, thirdScreen, progress);

  useMotionValueEvent(scrollYProgress, 'change', (latest) => {
    if (latest <= 1 / 3) {
      setActiveSlide(0);
      changeScreen(1);
    }
    if (latest > 1 / 3 && latest <= 2 / 3) {
      setActiveSlide(1);
      changeScreen(2);
    }
    if (latest > 2 / 3) {
      setActiveSlide(2);
      changeScreen(3);
    }
  });

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);

  const refs = [ref1, ref2, ref3];

  const sliderPaginationItems = [
    {
      ref: ref1,
      src: slide1Video,
      style: { height: firstScreenProgress },
      title: 'Build with ease',
      description:
        'Say goodbye to outdated drag-and-drop builders and greet a faster, more efficient way of building',
    },
    {
      ref: ref2,
      src: slide2Video,
      style: { height: secondScreenProgress },
      title: 'Stay on brand',
      description:
        'Revamp your brand effortlessly with Branding Guideline feature, seamlessly applying it to all your campaigns',
    },
    {
      ref: ref3,
      src: slide3Video,
      style: { height: thirdScreenProgress },
      title: 'Work together seamlessly',
      description:
        'Enhance your experience with multi-admin capabilities and streamline communication with comments',
    },
  ];

  const playVideos = () => {
    refs.forEach((ref) => {
      if (ref.current) {
        ref.current.play();
      }
    });
  };

  useEffect(() => {
    document.addEventListener('touchstart', playVideos);

    return () => {
      document.removeEventListener('touchstart', playVideos);
    };
  }, []);

  const changeSlide = (index) => () => {
    setActiveSlide(index);
  };

  const sectionTwoTitleAnimation = {
    hidden: {
      y: 20,
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <>
      <motion.div
        ref={targetRef}
        className={`section-two-container desktop slide-${activeSlide + 1}`}>
        <motion.div className="section-two-row">
          <div className="section-two-column column-left">
            <div className="section-two-content">
              <motion.div
                initial="hidden"
                whileInView="visible"
                exit="hidden"
                variants={sectionTwoTitleAnimation}
                viewport={{ amount: 0.7, once: true }}
                className="section-two-title-wrapper">
                <Label icon={widgetAddIcon}>build faster</Label>
                <Heading>Meet the future of email design</Heading>
              </motion.div>
              <div className="slider-pagination-wrapper">
                {sliderPaginationItems.map(({ style, title, description }, index) => {
                  return (
                    <motion.div
                      initial="hidden"
                      whileInView="visible"
                      exit="hidden"
                      variants={sectionTwoTitleAnimation}
                      viewport={{ amount: 0.7, once: true }}
                      key={index}
                      onClick={changeSlide(index)}
                      className={`slider-pagination-item${index === activeSlide ? ' active' : ''}`}>
                      {index === activeSlide && (
                        <div className="slider-pagination-progress">
                          <motion.div className="slider-pagination-progress-filled" style={style} />
                        </div>
                      )}
                      <span className="slider-pagination-title">{title}</span>
                      <span className="slider-pagination-description">{description}</span>
                    </motion.div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="section-two-column column-right">
            <div className={`section-two-images-container slide-${activeSlide + 1}`}>
              <AnimatePresence>
                {activeSlide === 0 && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { duration: 0.9, ease: 'easeIn' } }}
                    className="section-two-images-row">
                    <div className="section-two-image-wrapper">
                      <video
                        src={slide1Video}
                        width="100%"
                        height="100%"
                        playsInline={true}
                        autoPlay={true}
                        loop={true}
                        muted={true}
                      />
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
              <AnimatePresence>
                {activeSlide === 1 && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { duration: 0.9, ease: 'easeIn' } }}
                    className="section-two-images-row">
                    <div className="section-two-image-wrapper">
                      <video
                        src={slide2Video}
                        width="100%"
                        height="100%"
                        playsInline={true}
                        autoPlay={true}
                        loop={false}
                        muted={true}
                      />
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
              <AnimatePresence>
                {activeSlide === 2 && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { duration: 0.9, ease: 'easeIn' } }}
                    className="section-two-images-row">
                    <div className="section-two-image-wrapper">
                      <video
                        src={slide3Video}
                        width="100%"
                        height="100%"
                        playsInline={true}
                        autoPlay={true}
                        loop={true}
                        muted={true}
                      />
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        </motion.div>
      </motion.div>
      <div className="section-two-container mobile">
        <div className="section-two-row">
          <div className="section-two-title-wrapper">
            <Label icon={widgetAddIcon}>build faster</Label>
            <Heading>
              Meet the future <br />
              of email design
            </Heading>
          </div>
          <div className="section-two-carousel">
            <Carousel data={sliderPaginationItems} type={CarouselTypes.video} />
          </div>
        </div>
      </div>
    </>
  );
};

import { useState } from 'react';
import altArrowDownIcon from '../../assets/images/alt-arrow-down.svg';

export default function DropdownComponent({ data, selectedItem, onChange }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const onClick = (value) => () => {
    onChange?.(value);
    toggle();
  };

  return (
    <div className="dropdown">
      <div className="dropdown-toggle" onClick={toggle}>
        <span>{selectedItem}</span>
        <img src={altArrowDownIcon} alt="" />
      </div>
      {isOpen && (
        <div className="dropdown-menu">
          {data.map((item) => {
            return (
              <div key={item} className="dropdown-item" onClick={onClick(item)}>
                {item}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

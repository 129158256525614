import heart from '../../assets/images/heart.svg';
import ua from '../../assets/images/ua.svg';
import logo from '../../assets/images/Logo.svg';
import './style.scss';
import Button, { ButtonTypes } from '../ui/Button';
import addSimpleIcon from '../../assets/images/add-simple.svg';
import { useModal } from '../../hooks/useModal';
import { motion, useScroll, useTransform } from 'framer-motion';

const offsetY = [0, 300];
const offsetTop = [-90, 5];

export const Header = () => {
  const { toggleJoinModal, toggleCooperateModal } = useModal();
  const { scrollY } = useScroll();

  const topSize = useTransform(scrollY, offsetY, offsetTop);

  return (
    <motion.div className="header" style={{ top: topSize }}>
      <div className="header-row">
        <div className="header-column">
          <div className="header-logo-container">
            <img className="header-logo-image" src={logo} alt="logo" />
            <div className="header-logo-description">
              <span>Made with</span>
              <img src={heart} alt="heart" />
              <span>in</span>
              <img src={ua} alt="ua" />
              <span>Ukraine</span>
            </div>
          </div>
        </div>
        <div className="header-column">
          <div className="header-cta-container">
            <Button type={ButtonTypes.secondary} onClick={toggleCooperateModal}>
              Interested in partnering?
            </Button>
            <Button icon={addSimpleIcon} onClick={toggleJoinModal}>
              Join the waitlist
            </Button>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export const ButtonTypes = {
  primary: 'primary',
  secondary: 'secondary',
  success: 'success',
  warning: 'warning',
  danger: 'danger',
};

export default function Button({
  children,
  type = ButtonTypes.primary,
  icon,
  onClick = () => {},
  className,
  ...props
}) {
  return (
    <button
      className={`button ${type} ${icon ? 'has-icon' : ''} ${className ?? ''}`}
      onClick={onClick}
      {...props}>
      {icon && <img src={icon} alt="" />}
      {children}
    </button>
  );
}

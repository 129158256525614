import { motion, useInView } from 'framer-motion';
import Label from '../ui/Label';
import Heading from '../ui/Heading';
import subscribeVideo from '../../assets/videos/Features/Subscrube.mp4';
import abVideo from '../../assets/videos/Features/AB.mp4';
import abcVideo from '../../assets/videos/Features/A-B-C.mp4';
import dashboardVideo from '../../assets/videos/Features/Dashboard.mp4';
import clockVideo from '../../assets/videos/Features/Clock.mp4';
import usersVideo from '../../assets/videos/Features/Users.mp4';
import calendarVideo from '../../assets/videos/Features/Calendar.mp4';
import blackHoleIcon from '../../assets/images/black-hole.svg';
import './style.scss';
import { useEffect, useRef } from 'react';
import { useActiveScreen } from '../../hooks/useActiveScreen';

export const SectionFour = () => {
  const columnLeftAnimation = {
    hidden: { y: 30, opacity: 0 },
    visible: { y: 0, opacity: 1, transition: { duration: 0.7, ease: 'easeOut' } },
  };

  const columnRightAnimation = {
    hidden: { y: 30, opacity: 0 },
    visible: { y: 0, opacity: 1, transition: { duration: 0.7, delay: 0.5, ease: 'easeOut' } },
  };

  const { changeScreen } = useActiveScreen();
  const contentRef = useRef(null);
  const isContentInView = useInView(contentRef);

  const playVideos = () => {
    refs.forEach((ref) => {
      if (ref.current) {
        ref.current.play();
      }
    });
  };

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);

  const refs = [ref1, ref2, ref3, ref4, ref5, ref6, ref7];

  useEffect(() => {
    document.addEventListener('touchstart', playVideos);

    return () => {
      document.removeEventListener('touchstart', playVideos);
    };
  }, []);

  useEffect(() => {
    if (isContentInView) {
      changeScreen(5);
    }
  }, [isContentInView]);

  return (
    <div className="section-four-container">
      <div className="section-four-row">
        <div className="section-four-column">
          <motion.div
            initial="hidden"
            whileInView="visible"
            variants={columnLeftAnimation}
            viewport={{ amount: 0.5, once: true }}
            className="section-four-images-container">
            <div className="section-four-images-row-1">
              <div className="section-four-image-wrapper section-four-image-1">
                <video
                  ref={ref1}
                  preload="true"
                  width={'100%'}
                  height={'100%'}
                  playsInline={true}
                  autoPlay={true}
                  loop={true}
                  muted={true}>
                  <source src={subscribeVideo} type="video/mp4" />
                </video>
              </div>
              <div className="section-four-image-wrapper section-four-image-2">
                <video
                  ref={ref2}
                  preload="true"
                  width={'100%'}
                  height={'100%'}
                  playsInline={true}
                  autoPlay={true}
                  loop={true}
                  muted={true}>
                  <source src={abVideo} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="section-four-images-row-2">
              <div className="section-four-image-wrapper section-four-image-3">
                <video
                  ref={ref3}
                  preload="true"
                  width={'100%'}
                  height={'100%'}
                  playsInline={true}
                  autoPlay={true}
                  loop={true}
                  muted={true}>
                  <source src={abcVideo} type="video/mp4" />
                </video>
              </div>
              <div className="section-four-image-wrapper section-four-image-4">
                <video
                  ref={ref4}
                  preload="true"
                  width={'100%'}
                  height={'100%'}
                  playsInline={true}
                  autoPlay={true}
                  loop={true}
                  muted={true}>
                  <source src={dashboardVideo} type="video/mp4" />
                </video>
              </div>
              <div className="section-four-image-wrapper section-four-image-5">
                <video
                  ref={ref5}
                  preload="true"
                  width={'100%'}
                  height={'100%'}
                  playsInline={true}
                  autoPlay={true}
                  loop={true}
                  muted={true}>
                  <source src={clockVideo} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="section-four-images-row-3">
              <div className="section-four-image-wrapper section-four-image-6">
                <video
                  ref={ref6}
                  preload="true"
                  width={'100%'}
                  height={'100%'}
                  playsInline={true}
                  autoPlay={true}
                  loop={true}
                  muted={true}>
                  <source src={usersVideo} type="video/mp4" />
                </video>
              </div>
              <div className="section-four-image-wrapper section-four-image-7">
                <video
                  ref={ref7}
                  preload="true"
                  width={'100%'}
                  height={'100%'}
                  playsInline={true}
                  autoPlay={true}
                  loop={true}
                  muted={true}>
                  <source src={calendarVideo} type="video/mp4" />
                </video>
              </div>
            </div>
          </motion.div>
        </div>
        <div className="section-four-column">
          <motion.div
            ref={contentRef}
            initial="hidden"
            whileInView="visible"
            variants={columnRightAnimation}
            viewport={{ amount: 0.5, once: true }}
            className="section-four-content">
            <Label icon={blackHoleIcon}>grow stronger</Label>
            <Heading>Dozens of features to expand your marketing toolset</Heading>
            <div className="section-four-description">
              <span>
                Discover a diverse range of features that will enable you to evolve your email
                marketing experience to the next level.
              </span>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

import React from 'react';
import Marquee from 'react-fast-marquee';
import './style.scss';

export const RunningString = ({ ...props }) => {
  return (
    <div className="running-string">
      <Marquee autoFill={true} {...props}>
        <div className="running-string-item">
          <span>
            Automate campaigns ✦ Segment your audience ✦ Personalize content ✦ Boost engagement ✦
            Track performance ✦ Optimize conversions ✦ Build brand loyalty ✦ Nurture relationships ✦
          </span>
        </div>
      </Marquee>
    </div>
  );
};

import { useState } from 'react';
import { motion } from 'framer-motion';
import ReactDOM from 'react-dom';
import Button from './Button';
import addSimpleIcon from '../../assets/images/add-simple.svg';
import arrowRightIcon from '../../assets/images/arrow-right.svg';
import closeCircleIcon from '../../assets/images/close-circle.svg';
import Dropdown from './Dropdown';
import { useScreenSize } from '../../hooks/useScreenSize';
import { useModal } from '../../hooks/useModal';

const modalRoot = document.getElementById('modal-root');

export const ModalTypes = {
  join: 'join',
  cooperate: 'cooperate',
};

const modalTitle = {
  join: 'Join the Waitlist',
  cooperate: 'Let’s cooperate!',
};

const modalText = {
  join: "Join Dinomail's waitlist now and be at the forefront of innovation!",
  cooperate:
    'Interested in partnering with us to evolve email marketing to the next level? Let us know!',
};

const dropdownData = [
  'I’m interested in Investing',
  'I’m interested in Reselling',
  'I’m interested in Marketing',
  'Other',
];

const overlayVariants = {
  hidden: { opacity: 0, transition: { duration: 0.2 } },
  visible: { opacity: 1, transition: { duration: 0.2 } },
};

const contentVariants = {
  hidden: { scale: 0.3, opacity: 0, transition: { duration: 0.2, ease: 'easeOut' } },
  visible: { scale: 1, opacity: 1, transition: { duration: 0.2, ease: 'easeOut' } },
};

export default function Modal({ type = ModalTypes.join, onClick }) {
  const btnText = type === ModalTypes.cooperate ? 'send' : 'join';
  const btnIcon = type === ModalTypes.cooperate ? arrowRightIcon : addSimpleIcon;
  const { width } = useScreenSize();
  const { onEmailChange, onInterestsChange, subscribeWitelist, subscribeInterests } = useModal();

  const [dropdownItem, setDropdownItem] = useState(dropdownData[0]);

  const onDropdownItemChange = (value) => {
    setDropdownItem(value);
    onInterestsChange(value);
  };

  const onEmailInput = (value) => {
    onEmailChange(value);
  };

  const subscribe = type === ModalTypes.cooperate ? subscribeInterests : subscribeWitelist;

  return ReactDOM.createPortal(
    <motion.div
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={overlayVariants}
      className="modal">
      <motion.div
        initial="hidden"
        animate="visible"
        exit="hidden"
        variants={contentVariants}
        className="modal-content">
        <div className="modal-close-btn" onClick={onClick}>
          <img src={closeCircleIcon} alt="close" />
        </div>
        <h2 className="modal-title">{modalTitle[type]}</h2>
        <p className="modal-text">{modalText[type]}</p>
        {type === ModalTypes.cooperate && (
          <div className="modal-dropdown">
            <Dropdown
              selectedItem={dropdownItem}
              data={dropdownData}
              onChange={onDropdownItemChange}
            />
          </div>
        )}
        <div className="modal-form">
          <input
            type="text"
            placeholder="Your Email..."
            className="modal-input"
            onChange={(e) => onEmailInput(e.target.value)}
          />
          {width > 576 && (
            <Button icon={btnIcon} onClick={subscribe}>
              {btnText}
            </Button>
          )}
        </div>
        {width < 576 && (
          <Button icon={btnIcon} onClick={subscribe}>
            {btnText}
          </Button>
        )}
      </motion.div>
    </motion.div>,
    modalRoot,
  );
}

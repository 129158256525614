import { motion } from 'framer-motion';
import { useNotification } from '../../hooks/useNotification';
import { notificationText, NotificationTypes } from '../../constants';
import successIcon from '../../assets/images/check-circle.svg';
import warningIcon from '../../assets/images/danger-triangle.svg';
import { useScreenSize } from '../../hooks/useScreenSize';

const contentVariants = {
  hidden: { x: 570, transition: { duration: 0.2, ease: 'easeOut' } },
  visible: { x: 0, transition: { duration: 0.3, ease: 'easeOut' } },
};

const contentMobileVariants = {
  hidden: { y: 120, transition: { duration: 0.2, ease: 'easeOut' } },
  visible: { y: 0, transition: { duration: 0.3, ease: 'easeOut' } },
};

export const Notification = () => {
  const { width } = useScreenSize();
  const { notification } = useNotification();
  const type = notification || NotificationTypes.warning;
  const icon = notification === NotificationTypes.success ? successIcon : warningIcon;
  const variants = width > 576 ? contentVariants : contentMobileVariants;
  return (
    <motion.div
      className="notification"
      initial={false}
      animate={notification ? 'visible' : 'hidden'}
      variants={variants}>
      <div className="notification-wrapper">
        <img className="notification-icon" src={icon} alt="" />
        <span>{notificationText[type]}</span>
      </div>
    </motion.div>
  );
};

import React from 'react';
import loaderImage from '../../assets/images/Loader.gif';

export const Loader = () => {
  return (
    <div className="app-loader">
      <img src={loaderImage} alt="" />
    </div>
  );
};

import { useEffect, useRef, useState } from 'react';
import Typed from 'typed.js';
import { motion } from 'framer-motion';
import { useScreenSize } from '../../hooks/useScreenSize';
import { useModal } from '../../hooks/useModal';
import { useLoading } from '../../hooks/useLoading';
import Button, { ButtonTypes } from '../ui/Button';
import logo from '../../assets/images/Logo.svg';
import envelopesVideo from '../../assets/videos/Hero_Screen/Envelopes.mp4';
import rocketVideo from '../../assets/videos/Hero_Screen/Rocket.mp4';
import dollarsVideo from '../../assets/videos/Hero_Screen/Dollars.mp4';
import dinosaurVideo from '../../assets/videos/Hero_Screen/Dinosaur.mp4';
import robotVideo from '../../assets/videos/Hero_Screen/Robot.mp4';
import bicepsVideo from '../../assets/videos/Hero_Screen/Biceps.mp4';
import addSimpleIcon from '../../assets/images/add-simple.svg';
import rrrIcon from '../../assets/images/rrr.svg';
import { helloText } from '../../constants';
import './style.scss';

export const Hero = () => {
  const { width } = useScreenSize();
  const { toggleJoinModal, toggleCooperateModal } = useModal();
  const { loading, changeLoading } = useLoading();

  const [envelopesReady, setEnvelopesReady] = useState(false);
  const [rocketReady, setRocketReady] = useState(false);
  const [dollarsReady, setDollarsReady] = useState(false);
  const [dinosaurReady, setDinosaurReady] = useState(false);
  const [robotReady, setRobotReady] = useState(false);
  const [bicepsReady, setBicepsReady] = useState(false);

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);

  const refs = [ref1, ref2, ref3, ref4, ref5, ref6];

  const heroContentItemAnimation = {
    hidden: { y: 20, opacity: 0 },
    visible: (custom) => {
      return {
        y: 0,
        opacity: 1,
        transition: { duration: 1.5 - 0.15 * custom, ease: 'easeOut', delay: custom * 0.15 },
      };
    },
  };

  const heroImageItemAnimation = {
    hidden: { y: 20, opacity: 0 },
    visible: (custom) => {
      return {
        y: 0,
        opacity: 1,
        transition: { duration: 1.5 - 0.13 * custom, ease: 'easeOut', delay: custom * 0.13 },
      };
    },
  };

  const rrrAnimation = {
    hidden: { x: -10, y: 10, opacity: 0, rotate: 24 },
    visible: {
      x: 0,
      y: 0,
      opacity: 1,
      rotate: 24,
      transition: { duration: 0.3, ease: 'easeOut', delay: 2 },
    },
  };

  const el = useRef(null);

  const playVideos = () => {
    refs.forEach((ref) => {
      if (ref.current) {
        ref.current.play();
      }
    });
  };

  useEffect(() => {
    if (
      envelopesReady &&
      rocketReady &&
      dollarsReady &&
      dinosaurReady &&
      robotReady &&
      bicepsReady
    ) {
      changeLoading(false);
    }
  }, [envelopesReady, rocketReady, dollarsReady, dinosaurReady, robotReady, bicepsReady]);

  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: helloText,
      typeSpeed: 80,
      backSpeed: 50,
      startDelay: 2000,
      loop: true,
      showCursor: false,
    });

    document.addEventListener(width > 576 ? 'scroll' : 'touchstart', playVideos);

    return () => {
      typed.destroy();
      document.removeEventListener(width > 576 ? 'scroll' : 'touchstart', playVideos);
    };
  }, []);

  return (
    <div className="hero-container">
      <div className="hero-row">
        <div className="hero-column">
          <div className="hero-content">
            <motion.div
              initial="hidden"
              animate={loading ? 'hidden' : 'visible'}
              custom={0}
              variants={heroContentItemAnimation}
              className="hero-logo-container">
              <img className="hero-logo-image" src={logo} alt="logo" />
            </motion.div>
            <div className="hero-slogan-container">
              <motion.div
                initial="hidden"
                animate={loading ? 'hidden' : 'visible'}
                className="hero-slogan-title">
                <motion.span custom={1} variants={heroContentItemAnimation}>
                  Faster.
                </motion.span>
                <motion.span custom={2} variants={heroContentItemAnimation}>
                  Smarter.
                </motion.span>
                <motion.span custom={3} variants={heroContentItemAnimation}>
                  Strongerrr.
                </motion.span>
              </motion.div>
              <motion.span
                initial="hidden"
                animate={loading ? 'hidden' : 'visible'}
                variants={heroContentItemAnimation}
                custom={4}
                className="hero-slogan-description">
                Efficient email marketing, reimagined and refined for unparalleled performance
              </motion.span>
            </div>
            <motion.div
              initial="hidden"
              animate={loading ? 'hidden' : 'visible'}
              custom={5}
              variants={heroContentItemAnimation}
              className="hero-cta-container">
              <Button icon={addSimpleIcon} onClick={toggleJoinModal}>
                Join the waitlist
              </Button>
              <Button type={ButtonTypes.secondary} onClick={toggleCooperateModal}>
                Interested in Investing?
              </Button>
            </motion.div>
          </div>
        </div>
        <div className="hero-column">
          <div className="hero-images-container">
            <div className="hero-images-row-1">
              <motion.div
                initial="hidden"
                animate={loading ? 'hidden' : 'visible'}
                custom={0}
                variants={heroImageItemAnimation}
                className="hero-image-wrapper hero-image-1">
                <span ref={el} />
                <span className="hero-image-cursor">|</span>
              </motion.div>
              <motion.div
                initial="hidden"
                animate={loading ? 'hidden' : 'visible'}
                custom={1}
                variants={heroImageItemAnimation}
                className="hero-image-wrapper hero-image-2">
                <video
                  ref={ref1}
                  onCanPlayThrough={() => setEnvelopesReady(true)}
                  preload="true"
                  width={'100%'}
                  height={'100%'}
                  controls={false}
                  playsInline={true}
                  autoPlay={true}
                  loop={true}
                  muted={true}>
                  <source src={envelopesVideo} type="video/mp4" />
                </video>
              </motion.div>
            </div>
            <div className="hero-images-row-2">
              <motion.div
                initial="hidden"
                animate={loading ? 'hidden' : 'visible'}
                custom={2}
                variants={heroImageItemAnimation}
                className="hero-image-wrapper hero-image-3">
                <video
                  ref={ref2}
                  onCanPlayThrough={() => setRocketReady(true)}
                  preload="true"
                  width={'100%'}
                  height={'100%'}
                  controls={false}
                  playsInline={true}
                  autoPlay={true}
                  loop={true}
                  muted={true}>
                  <source src={rocketVideo} type="video/mp4" />
                </video>
              </motion.div>
              <motion.div
                initial="hidden"
                animate={loading ? 'hidden' : 'visible'}
                custom={3}
                variants={heroImageItemAnimation}
                className="hero-image-wrapper hero-image-4">
                <video
                  ref={ref3}
                  onCanPlayThrough={() => setDollarsReady(true)}
                  preload="true"
                  width={'100%'}
                  height={'100%'}
                  controls={false}
                  playsInline={true}
                  autoPlay={true}
                  loop={true}
                  muted={true}>
                  <source src={dollarsVideo} type="video/mp4" />
                </video>
              </motion.div>
            </div>
            <div className="hero-images-row-3">
              <motion.div
                initial="hidden"
                animate={loading ? 'hidden' : 'visible'}
                custom={4}
                variants={heroImageItemAnimation}
                className="hero-image-wrapper hero-image-5">
                <video
                  ref={ref4}
                  onCanPlayThrough={() => setDinosaurReady(true)}
                  preload="true"
                  style={{ borderRadius: 999, overflow: 'hidden' }}
                  width={'100%'}
                  height={'100%'}
                  controls={false}
                  playsInline={true}
                  autoPlay={true}
                  loop={true}
                  muted={true}>
                  <source src={dinosaurVideo} type="video/mp4" />
                </video>
                <motion.img
                  initial="hidden"
                  animate={loading ? 'hidden' : 'visible'}
                  variants={rrrAnimation}
                  className="rrr-image"
                  src={rrrIcon}
                  alt=""
                />
              </motion.div>
              <motion.div
                initial="hidden"
                animate={loading ? 'hidden' : 'visible'}
                custom={5}
                variants={heroImageItemAnimation}
                className="hero-image-wrapper hero-image-6">
                <video
                  ref={ref5}
                  onCanPlayThrough={() => setRobotReady(true)}
                  preload="true"
                  width={'100%'}
                  height={'100%'}
                  controls={false}
                  playsInline={true}
                  autoPlay={true}
                  loop={true}
                  muted={true}>
                  <source src={robotVideo} type="video/mp4" />
                </video>
              </motion.div>
              <motion.div
                initial="hidden"
                animate={loading ? 'hidden' : 'visible'}
                custom={6}
                variants={heroImageItemAnimation}
                className="hero-image-wrapper hero-image-7">
                <video
                  ref={ref6}
                  onCanPlayThrough={() => setBicepsReady(true)}
                  preload="true"
                  width={'100%'}
                  height={'100%'}
                  controls={false}
                  playsInline={true}
                  autoPlay={true}
                  loop={true}
                  muted={true}>
                  <source src={bicepsVideo} type="video/mp4" />
                </video>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

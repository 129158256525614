export const helloText = [
  'Hello',
  'Kumusta',
  '你好',
  'Hola',
  'Привіт',
  'Hallo',
  'नमस्ते',
  'Bonjour',
];

export const NotificationTypes = {
  success: 'success',
  warning: 'warning',
};

export const notificationText = {
  success: 'Everything went well! Thank you for joining the Dinomali family.',
  warning: 'Something went wrong, please reload the page and try again.',
};

import React, { memo, useEffect, useState, useRef } from 'react';
import Matter from 'matter-js';
import { useScreenSize } from '../../hooks/useScreenSize';
import jobTitleDesktopIcon from '../../assets/images/job-title.svg';
import jobTitleMobileIcon from '../../assets/images/job-title-mobile.svg';
import fullNameDesktopIcon from '../../assets/images/full-name.svg';
import fullNameMobileIcon from '../../assets/images/full-name-mobile.svg';
import companyDesktopIcon from '../../assets/images/company.svg';
import companyMobileIcon from '../../assets/images/company-mobile.svg';
import cityDesktopIcon from '../../assets/images/city-body.svg';
import cityMobileIcon from '../../assets/images/city-mobile.svg';
import ageDesktopIcon from '../../assets/images/age.svg';
import ageMobileIcon from '../../assets/images/age-mobile.svg';
import stateDesktopIcon from '../../assets/images/state.svg';
import stateMobileIcon from '../../assets/images/state-mobile.svg';
import sexDesktopIcon from '../../assets/images/sex.svg';
import sexMobileIcon from '../../assets/images/sex-mobile.svg';
import postalCodeDesktopIcon from '../../assets/images/postal-code.svg';
import postalCodeMobileIcon from '../../assets/images/postal-code-mobile.svg';

const STATIC_DENSITY = 1;
const PARTICLE_SIZE = 50;
const PARTICLE_BOUNCYNESS = 0.9;

export const Pounder = memo(() => {
  const boxRef = useRef(null);
  const [constraints, setConstraints] = useState();
  const [scene, setScene] = useState();
  const [someStateValue, setSomeStateValue] = useState(false);
  const { width } = useScreenSize();

  const getOffset = (desktop, mobile) => {
    if (width < 576) return mobile;
    return desktop;
  };

  const jobTitleIcon = getOffset(jobTitleDesktopIcon, jobTitleMobileIcon);
  const fullNameIcon = getOffset(fullNameDesktopIcon, fullNameMobileIcon);
  const companyIcon = getOffset(companyDesktopIcon, companyMobileIcon);
  const cityIcon = getOffset(cityDesktopIcon, cityMobileIcon);
  const ageIcon = getOffset(ageDesktopIcon, ageMobileIcon);
  const stateIcon = getOffset(stateDesktopIcon, stateMobileIcon);
  const sexIcon = getOffset(sexDesktopIcon, sexMobileIcon);
  const postalCodeIcon = getOffset(postalCodeDesktopIcon, postalCodeMobileIcon);

  const itemHeight = getOffset(58, 48);
  const itemRadius = getOffset(16, 13);
  const jobTitleWidth = getOffset(148, 119);
  const fullNameWidth = getOffset(165, 133);
  const companyWidth = getOffset(159, 128);
  const cityWidth = getOffset(103, 84);
  const ageWidth = getOffset(102, 83);
  const stateWidth = getOffset(117, 95);
  const sexWidth = getOffset(100, 81);
  const postalCodeWidth = getOffset(186, 150);

  const handleResize = () => {
    setConstraints(boxRef.current.getBoundingClientRect());
  };

  const handleClick = () => {
    setSomeStateValue(!someStateValue);
  };

  useEffect(() => {
    let Engine = Matter.Engine;
    let Runner = Matter.Runner;
    let Render = Matter.Render;
    let World = Matter.World;
    let Bodies = Matter.Bodies;
    let Mouse = Matter.Mouse;
    let MouseConstraint = Matter.MouseConstraint;

    let engine = Engine.create();

    let render = Render.create({
      element: boxRef.current,
      engine: engine,
      options: {
        background: 'transparent',
        wireframes: false,
        pixelRatio: 2,
        hasBounds: true,
      },
    });

    let mouse = Mouse.create(render.canvas);
    let mouseConstraint = MouseConstraint.create(engine, {
      mouse: mouse,
      constraint: {
        stiffness: 0.5,
        render: {
          visible: false,
        },
      },
    });

    World.add(engine.world, mouseConstraint);

    // Walls
    const floor = Bodies.rectangle(0, 0, 0, STATIC_DENSITY, {
      isStatic: true,
      render: {
        fillStyle: 'black',
      },
    });

    const left = Bodies.rectangle(0, 0, 0, STATIC_DENSITY, {
      isStatic: true,
      render: {
        fillStyle: 'black',
      },
    });

    const right = Bodies.rectangle(0, 0, 0, STATIC_DENSITY, {
      isStatic: true,
      render: {
        fillStyle: 'black',
      },
    });

    const ceiling = Bodies.rectangle(0, 0, 0, STATIC_DENSITY, {
      isStatic: true,
      render: {
        fillStyle: 'black',
      },
    });

    // Items
    const jobTitle = Bodies.rectangle(PARTICLE_SIZE, PARTICLE_SIZE, jobTitleWidth, itemHeight, {
      chamfer: { radius: itemRadius },
      render: {
        fillStyle: 'transparent',
        sprite: {
          texture: jobTitleIcon,
        },
      },
    });

    const fullName = Bodies.rectangle(
      PARTICLE_SIZE * 1.1,
      PARTICLE_SIZE,
      fullNameWidth,
      itemHeight,
      {
        chamfer: { radius: itemRadius },
        render: {
          fillStyle: 'transparent',
          sprite: {
            texture: fullNameIcon,
          },
        },
      },
    );

    const company = Bodies.rectangle(PARTICLE_SIZE * 1.2, PARTICLE_SIZE, companyWidth, itemHeight, {
      chamfer: { radius: itemRadius },
      render: {
        fillStyle: 'transparent',
        sprite: {
          texture: companyIcon,
        },
      },
    });

    const city = Bodies.rectangle(PARTICLE_SIZE * 1.3, PARTICLE_SIZE, cityWidth, itemHeight, {
      chamfer: { radius: itemRadius },
      render: {
        fillStyle: 'transparent',
        sprite: {
          texture: cityIcon,
        },
      },
    });

    const age = Bodies.rectangle(PARTICLE_SIZE * 1.4, PARTICLE_SIZE, ageWidth, itemHeight, {
      chamfer: { radius: itemRadius },
      render: {
        fillStyle: 'transparent',
        sprite: {
          texture: ageIcon,
        },
      },
    });

    const state = Bodies.rectangle(PARTICLE_SIZE, PARTICLE_SIZE, stateWidth, itemHeight, {
      chamfer: { radius: itemRadius },
      render: {
        fillStyle: 'transparent',
        sprite: {
          texture: stateIcon,
        },
      },
    });

    const sex = Bodies.rectangle(PARTICLE_SIZE, PARTICLE_SIZE, sexWidth, itemHeight, {
      chamfer: { radius: itemRadius },
      render: {
        fillStyle: 'transparent',
        sprite: {
          texture: sexIcon,
        },
      },
    });

    const postalCode = Bodies.rectangle(PARTICLE_SIZE, PARTICLE_SIZE, postalCodeWidth, itemHeight, {
      chamfer: { radius: itemRadius },
      render: {
        fillStyle: 'transparent',
        sprite: {
          texture: postalCodeIcon,
        },
      },
    });

    World.add(engine.world, [
      floor,
      left,
      right,
      ceiling,
      jobTitle,
      fullName,
      company,
      city,
      age,
      state,
      sex,
      postalCode,
    ]);

    Runner.run(engine);
    Render.run(render);

    setConstraints(boxRef.current.getBoundingClientRect());
    setScene(render);

    window.addEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (constraints) {
      let { width, height } = constraints;

      // Dynamically update bounds
      scene.bounds.max.x = width;
      scene.bounds.max.y = height;
      scene.options.width = width;
      scene.options.height = height;

      // Dynamically update walls
      const floor = scene.engine.world.bodies[0];

      Matter.Body.setPosition(floor, {
        x: width / 2,
        y: height,
      });

      Matter.Body.setVertices(floor, [
        { x: 0, y: height - STATIC_DENSITY },
        { x: width, y: height - STATIC_DENSITY },
        { x: width, y: height + STATIC_DENSITY },
        { x: 0, y: height + STATIC_DENSITY },
      ]);

      const left = scene.engine.world.bodies[1];

      Matter.Body.setPosition(left, {
        x: 0,
        y: height / 2,
      });

      Matter.Body.setVertices(left, [
        { x: 0, y: STATIC_DENSITY },
        { x: STATIC_DENSITY, y: STATIC_DENSITY },
        { x: STATIC_DENSITY, y: height - STATIC_DENSITY },
        { x: 0, y: height - STATIC_DENSITY },
      ]);

      const right = scene.engine.world.bodies[2];

      Matter.Body.setPosition(right, {
        x: width,
        y: height / 2,
      });

      Matter.Body.setVertices(right, [
        { x: 0, y: STATIC_DENSITY },
        { x: STATIC_DENSITY, y: STATIC_DENSITY },
        { x: STATIC_DENSITY, y: height - STATIC_DENSITY },
        { x: 0, y: height - STATIC_DENSITY },
      ]);

      const ceiling = scene.engine.world.bodies[3];

      Matter.Body.setPosition(ceiling, {
        x: width / 2,
        y: 0,
      });

      Matter.Body.setVertices(ceiling, [
        { x: 0, y: height - STATIC_DENSITY },
        { x: width, y: height - STATIC_DENSITY },
        { x: width, y: height + STATIC_DENSITY },
        { x: 0, y: height + STATIC_DENSITY },
      ]);
    }
  }, [scene, constraints]);

  useEffect(() => {
    // Add a new "ball" everytime `someStateValue` changes
    if (scene) {
      let { width } = constraints;
      let randomX = Math.floor(Math.random() * -width) + width;
      Matter.World.add(
        scene.engine.world,
        Matter.Bodies.circle(randomX, -PARTICLE_SIZE, PARTICLE_SIZE, {
          restitution: PARTICLE_BOUNCYNESS,
        }),
      );
    }
  }, [scene, someStateValue]);

  return (
    <div
      ref={boxRef}
      style={{
        position: 'absolute',
        top: '-1px',
        left: '-1px',
        width: 'calc(100% + 2px)',
        height: 'calc(100% + 2px)',
      }}></div>
  );
});
